import http from "../../utils/http"



/**
 * 红包领取记录      
 */
export function redPacketList(params) {
    return http({
        url: "/redpacket/api/redpacket/list",
        data: params,
        forceLogin: true
    })
}




/**
 * 获取红包详情  
 * code: CSP5205794025
 * lead_type: 1
 * 
 */
export function redInfo(params) {
    return http({
        url: "/redpacket/api/redpacket/info",
        data: params,
        forceLogin: true
    })
}



/**
 * 红包兑换
 * record_id: 69055
 *lead_type: 1
 */
export function leadRedpacket(params) {
    return http({
        url: "/redpacket/api/redpacket/leadRedpacket",
        data: params,
        forceLogin: true
    })
}


