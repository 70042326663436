<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<el-card class="box-card order-list">
			<div slot="header" class="clearfix"><span>我的红包</span></div>

			<el-tabs v-model="orderStatus" @tab-click="handleClick">
				<el-tab-pane label="全部" name="all"></el-tab-pane>
				<el-tab-pane label="已领取" name="1"></el-tab-pane>
				<el-tab-pane label="未领取" name="2"></el-tab-pane>
				<el-tab-pane label="已到期" name="3"></el-tab-pane>
			</el-tabs>

			<div v-loading="loading">
				<nav>
					<li>红包二维码</li>
					<li>红包编号</li>
					<li>到期时间</li>
					<li>红包状态</li>
					<li>操作</li>
				</nav>

				<div class="list" v-if="orderList.length > 0">
					<div class="item" v-for="(orderItem, orderIndex) in orderList">
						<div class="head">
							<span class="create-time">{{ $util.timeStampTurnTime(orderItem.create_time) }}</span>
							<span class="order-no">红包开头：{{ orderItem.redpacket_name }}</span>
							<router-link :to="'/shop-' + orderItem.surplus_money" target="_blank">{{ orderItem.surplus_money }}</router-link>
							<span class="order-no">红包金额：{{ orderItem.money }}</span>
						</div>
						<ul>
							<li>
								<div class="img-wrap"><img @click="getFloatLayer(orderItem.redpacket_qrcode_url)" :src="$img(orderItem.redpacket_qrcode_url)" /></div>
							</li>
							<li>
								<span>{{ orderItem.code }}</span>
							</li>
							<li>
								<span>{{ orderItem.valid_end_time }}</span>
							</li>
							<li>
								<span v-if="orderItem.status == 1">未领取</span>
								<span v-else-if="orderItem.status == 2">已领取</span>
								<span v-else>已到期</span>
							</li>
							<li><span class="lingqu" v-if="orderItem.status == 1" @click="leadRedpacket_(orderItem)">领取</span></li>
						</ul>
					</div>
				</div>
				<div v-else-if="!loading && orderList.length == 0" class="empty-wrap">暂无相关数据</div>
			</div>
			<!-- 浮层区 -->
			<div class="floatLayer-wrap" v-if="floatLayer.is_show">
				<div class="floatLayer">
					<img :src="$img(floatLayer.img_url)" />
					<i class="el-icon-circle-close" @click="closeFloat"></i>
				</div>
			</div>
			<div class="pager">
				<el-pagination
					background
					:pager-count="5"
					:total="total"
					prev-text="上一页"
					next-text="下一页"
					:current-page.sync="currentPage"
					:page-size.sync="pageSize"
					@size-change="handlePageSizeChange"
					@current-change="handleCurrentPageChange"
					hide-on-single-page
				></el-pagination>
			</div>
		</el-card>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { redPacketList, leadRedpacket } from '@/api/member/my_red_list';
import orderMethod from '@/utils/orderMethod';
export default {
	name: 'order_list',
	components: {},
	data: () => {
		return {
			orderStatus: '0',
			loading: true,
			orderList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0,
			yes: true,
			floatLayer: {
				is_show: 0,
				img_url: '',
				link: {
					url: ''
				}
			}
		};
	},
	mixins: [orderMethod],
	created() {
		this.orderStatus = this.$route.query.status || '0';
		this.getRedPacketList();
	},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	mounted() {
		let self = this;
		setTimeout(function() {
			self.yes = false;
		}, 300);
	},
	methods: {
		handleClick(tab, event) {
			this.currentPage = 1;
			this.orderStatus = tab.name;
			this.refresh();
		},
		getRedPacketList() {
			redPacketList({
				page: this.currentPage,
				page_size: this.pageSize,
				type: this.orderStatus
			})
				.then(res => {
					let list = [];
					if (res.code == 0 && res.data) {
						list = res.data.list;
						this.total = res.data.count;
					}
					this.orderList = list;

					console.log('getRedPacketList', this.orderList);

					this.loading = false;
				})
				.catch(res => {
					this.loading = false;
				});
		},
		handlePageSizeChange(size) {
			this.pageSize = size;
			this.refresh();
		},
		handleCurrentPageChange(page) {
			this.currentPage = page;
			this.refresh();
		},
		refresh() {
			this.loading = true;
			this.getRedPacketList();
		},
		//红包领取
		leadRedpacket_(data) {
			var new_data = {
				record_id: data.record_id,
				lead_type: 1
			};
			leadRedpacket(new_data)
				.then(res => {
					if (res.code == 0) {
						this.$message({ message: '领取成功', type: 'warning' });
						this.getRedPacketList();
					} else {
						this.flag = false;
						this.$message({ message: res.message, type: 'warning' });
					}
				})
				.catch(err => {
					this.flag = false;
					this.$message.error(err.message);
				});
		},
		getFloatLayer(qrcode_url) {
			this.floatLayer.is_show = !this.floatLayer.is_show;
			this.floatLayer.img_url = qrcode_url;
		},
		closeFloat() {
			this.floatLayer.is_show = !this.floatLayer.is_show;
		}
	}
};
</script>
<style lang="scss" scoped>
.vue-directive-image-previewer-body img {
	position: absolute;
	z-index: 1;
	width: 200px !important;
	height: 200px !important;
}

.box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #ffffff;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.order-list {
	nav {
		overflow: hidden;
		padding: 10px 0;
		background: #fff;
		margin-bottom: 10px;
		border-bottom: 1px solid #eeeeee;
		li {
			float: left;
			&:nth-child(1) {
				width: 20%;
			}
			&:nth-child(2) {
				width: 20%;
			}
			&:nth-child(3) {
				width: 20%;
			}
			&:nth-child(4) {
				width: 20%;
			}
			&:nth-child(5) {
				width: 20%;
			}
		}
	}
	.list {
		.item {
			margin-bottom: 20px;
			border: 1px solid #eeeeee;
			border-top: 0;
			.head {
				padding: 8px 10px;
				background: #f7f7f7;
				font-size: 12px;

				.create-time {
					margin-right: 10px;
				}

				border-bottom: 1px solid #eeeeee;
				a {
					margin: 0 10px 0 20px;
				}

				.order-type {
					margin-left: 30px;
				}
			}
		}
		ul {
			background-color: #fff;
			padding: 10px;
			overflow: hidden;
			li {
				float: left;
				line-height: 60px;
				&:nth-child(1) {
					width: 20%;
					line-height: inherit;
					.img-wrap {
						width: 60px;
						height: 60px;
						float: left;
						margin-right: 10px;
						cursor: pointer;
					}
					.info-wrap {
						margin-left: 70px;
						h5 {
							font-weight: normal;
							font-size: $ns-font-size-base;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
							margin-right: 10px;
							display: inline-block;
							cursor: pointer;
							&:hover {
								color: $base-color;
							}
						}
						span {
							font-size: $ns-font-size-sm;
							color: #9a9a9a;
						}
					}
				}
				&:nth-child(2) {
					width: 20%;
				}
				&:nth-child(3) {
					width: 20%;
				}
				&:nth-child(4) {
					width: 20%;
				}
				&:nth-child(5) {
					width: 20%;
					line-height: 60px;
					a {
						display: block;
					}
				}
				// &:nth-child(6) {
				// 	width: 20%;
				// 	line-height: initial;
				// 	button {
				// 		margin-left: 0;
				// 		margin-bottom: 10px;
				// 		&:last-child {
				// 			margin-bottom: 0;
				// 		}
				// 	}
				// }
			}
		}
	}
	.empty-wrap {
		text-align: center;
		padding: 10px;
	}

	.floatLayer-wrap {
		position: fixed;
		height: 100vh;
		width: 100vw;
		left: 0;
		top: 0;
		background: rgba($color: #000000, $alpha: 0.5);
		z-index: 999;

		.floatLayer {
			position: fixed;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			z-index: 999;
			cursor: pointer;

			i {
				color: #ffffff;
				font-size: 30px;
				position: absolute;
				top: -30px;
				right: -30px;
			}
		}
	}
}
.lingqu{
	cursor: pointer;
}
</style>
